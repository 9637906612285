<template>
  <div>
    <el-dialog
      :title="title"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small" label-width="120px">
        <el-form-item label="账单延期数：" prop="refundPrice">
          <el-select
            style="width: 100%"
            v-model="form.delayCount"
            placeholder="请选择"
          >
            <el-option
              v-for="item in delayCountList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { applyBillDelay } from "@/api/bill.js";

export default {
  name: "applyBillDelay",

  data() {
    return {
      title: "账单延期",
      dialogFormVisible: false,
      form: {
        delayCount: 1,
      },
      delayCountList: [
        {
          value: 1,
          label: "1期",
        },
        {
          value: 2,
          label: "2期",
        },
        {
          value: 3,
          label: "3期",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    async isShow(billNo) {
      this.dialogFormVisible = true;
      this.form.billNo = billNo;
    },

    async confirm() {
      const { code } = await applyBillDelay(this.form);
      if (code == 200) {
        this.$message.success("延期成功");
        this.close();
        this.$parent.initData();
      }
    },
    close() {
      this.dialogFormVisible = false;
      this.form = {
        delayCount: 1,
      };
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>