import request from '@/utils/request'

// 订单列表
export function listBill (params) {
  return request.post('/api/listBill', params)
}

// 订单详情
export function infoBill (params) {
  return request.post('/api/infoBill', params)
}

// 分账订单
export function ledgerOrderList (params) {
  return request.post('/api/ledgerOrderList', params)
}

// 申请分账
export function applyLedger (params) {
  return request.post('/api/applyLedger', params)
}

// 分账错误记录
export function ledgerFailList (params) {
  return request.post('/api/ledgerFailList', params)
}

// 申请账单退费
export function applyRefund (params) {
  return request.post('/api/applyRefund', params)
}

// 取消账单退费
export function cancelRefund (params) {
  return request.post('/api/cancelRefund', params)
}

//账单审核
export function billApprove (params) {
  return request.post('/api/billApprove', params)
}

//账单备注
export function billRemark (params) {
  return request.post('/api/billRemark', params)
}

//分期账单还款列表
export function billStageRepaymentList (params) {
  return request.post('/api/billStageRepaymentList', params)
}

//账单延期
export function applyBillDelay (params) {
  return request.post('/api/applyBillDelay', params)
}

// 添加催收记录
export function addBillStageOverdueUrgeRecord (params) {
  return request.post('/api/addBillStageOverdueUrgeRecord', params)
}

// 催收记录列表
export function billStageOverdueUrgeRecordList (params) {
  return request.post('/api/billStageOverdueUrgeRecordList', params)
}

// 修改还款日期
export function billDateChange(params) {
  return request.post('/api/changeBill', params)
}

// 获取修改日期权限
export function getBillDateState(params) {
  return request.post('/api/changeBillStatus', params)
}

// 延期还款
export function reliefStage(params) {
  return request.post('/api/reliefStage', params)
}