<template>
  <div class="approveModal">
    <el-dialog
      :title="title"
      width="520px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="accountModal"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="submitForm"
        size="small"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="审核状态：" prop="approveStatus">
          <el-radio-group v-model="form.approveStatus">
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="2">审核不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核原因：">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入审核原因"
            v-model="form.reason"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "approveModal",

  data() {
    return {
      title: "",
      dialogFormVisible: false,
      form: {
        approveStatus: '',
        reason: ''
      },
      rules: {
        approveStatus: [
          { required: true, message: "请选择审核状态", trigger: "change" },
        ],
      },
    };
  },

  mounted() {},

  methods: {
    async isShow(title, item, bill_id) {
      this.title = title;
      this.dialogFormVisible = true;
      this.form.approveId = item.id;
      this.form.billId = bill_id;
    },
    confirm() {
      if(!this.form.approveStatus){
        this.$message.info("请选择审核状态");
        return
      }
      this.$emit('approveConfim',this.form)
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs["submitForm"].resetFields();
    },
    handleClose(done) {
      this.close();
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
.approveModal {
  /deep/ .accountModal {
    border-radius: 10px !important;
    .el-dialog__header {
      border-bottom: 1px solid #e8eaec;
      .el-dialog__title {
        font-size: 14px;
        color: #17233d;
        font-weight: 700;
      }
    }
    .el-dialog__footer {
      border-top: 1px solid #e8eaec;
    }
  }
}
</style>