<template>
  <div class="ordetail">
    <div class="opdetcru">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/order/all' }"
          >全部账单</el-breadcrumb-item
        >
        <el-breadcrumb-item>账单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="basic-info flex">
      <div style="display: flex; flex: 1">
        <div class="user-info">
          <div class="head-pic">
            <i class="el-icon-user-solid"></i>
          </div>
          <div class="head-bd">
            <span class="name mr_20">{{ billInfo.real_name }}</span>
            <span class="node">{{ billInfo.id_card_no }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabbarsa">
      <el-tabs
        class="elTabs"
        type="card"
        :value="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="账单信息" name="账单信息" />
        <el-tab-pane
          label="费用信息"
          name="费用信息"
          v-if="billStageList.length != 0"
        />
        <el-tab-pane
          label="催收信息"
          name="催收信息"
          v-if="managerType != 0 || getAuth('urgeOrderList')"
        />
      </el-tabs>
    </div>
    <div class="deitem" v-if="activeName == '账单信息'">
      <div class="progress">
        <div class="prosteps" v-if="approveList && approveList.length">
          <el-steps :active="1" finish-status="success" process-status="finish">
            <el-step
              v-for="(item, index) in approveList"
              :key="index"
              :status="finishStatus[item.approve_status]"
            >
              <template #title>
                <span v-if="item.approve_node == 0">账单申请</span>
                <span v-if="item.approve_node == 1">资料审核</span>
                <span v-if="item.approve_node == 2">商家确认</span>
                <span v-if="item.approve_node == 3">分期成功</span>
              </template>
              <template #description>
                <div>{{ item.approve_time }}</div>
                <div v-if="item.approve_reason != ''">
                  审核原因：{{ item.approve_reason }}
                </div>
                <div v-if="item.approve_node == 1 && item.approve_status == 0">
                  <el-button
                    type="primary"
                    size="small"
                    :disabled="isApproveAuth ? false : true"
                    @click="onApproveData(item)"
                    >审核资料</el-button
                  >
                </div>
                <div
                  v-if="
                    item.approve_node == 2 &&
                    item.approve_status == 0 &&
                    approveList[1].approve_status == 1
                  "
                >
                  <el-button
                    type="primary"
                    size="small"
                    :disabled="isApproveAuth ? false : true"
                    @click="onApproveInstitution(item)"
                    >审核</el-button
                  >
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
        <div class="refundInfo">
          <div v-if="billInfo.bill_status == 1">
            <el-button
              type="primary"
              v-if="billInfo.refund_status == 0"
              @click="onRefund"
              :disabled="isRefundAuth? false : true"
              >发起退款</el-button
            >
          </div>
          <div v-if="billInfo.refund_status == 1">
            <div class="refundTitle">已申请退款</div>
            <el-button @click="onCancelRefund">取消退款</el-button>
          </div>
          <div v-if="billInfo.refund_status == 2">
            <div class="refundTitle">已退款</div>
            <el-button @click="getCheckRedund">查看</el-button>
          </div>
        </div>
      </div>
      <el-card shadow="hover" class="cardInfo">
        <el-descriptions
          class="margin-top"
          labelClassName="labelc"
          contentClassName="connamc"
          title="个人信息"
          :column="2"
          size="medium"
          border
        >
          <el-descriptions-item label="姓名">{{
            billInfo.real_name
          }}</el-descriptions-item>
          <el-descriptions-item label="手机号码">{{
            billInfo.mobile
          }}</el-descriptions-item>
          <el-descriptions-item label="身份证号">{{
            billInfo.id_card_no
          }}</el-descriptions-item>
          <el-descriptions-item label="实名认证">
            <span v-if="billInfo.real_auth_status == 0">未认证</span>
            <span v-if="billInfo.real_auth_status == 1">已认证</span>
          </el-descriptions-item>
          <el-descriptions-item label="学历">{{
            billInfo.education
          }}</el-descriptions-item>
          <el-descriptions-item label="现居住地址">{{
            billInfo.residence_address
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人A">
            {{ billInfo.first_relation_type }}：{{
              billInfo.first_relation_user_name
            }}
            {{ billInfo.first_relation_user_mobile }}
          </el-descriptions-item>
          <el-descriptions-item label="联系人B">
            {{ billInfo.second_relation_type }}：{{
              billInfo.second_relation_user_name
            }}
            {{ billInfo.second_relation_user_mobile }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card shadow="hover" class="cardInfo">
        <el-descriptions
          class="margin-top"
          labelClassName="labelc"
          contentClassName="connamc"
          title="账单信息"
          :column="2"
          size="medium"
          border
        >
          <el-descriptions-item label="账单号">{{
            billInfo.bill_no
          }}</el-descriptions-item>
          <el-descriptions-item label="账单状态">
            <span v-if="billInfo.bill_status == 0">
              <span v-if="billInfo.approve_status == 0">审核中</span>
              <span v-if="billInfo.approve_status == 1">已审核</span>
              <span v-if="billInfo.approve_status == 2">审核不通过</span>
            </span>
            <span v-if="billInfo.bill_status == 1">
              生效中
              <span v-if="billInfo.is_overdue == 1">（已逾期）</span>
              <span v-if="billInfo.refund_status == 1">（已申请退款）</span>
            </span>
            <span v-if="billInfo.bill_status == 2">已完结</span>
            <span v-if="billInfo.bill_status == 3">已关闭</span>
            <el-button
              v-if="billInfo.bill_status == 4"
              type="text"
              @click="getCheckRedund"
              >已退款</el-button
            >
          </el-descriptions-item>
          <el-descriptions-item label="商家名称">{{
            billInfo.full_name
          }}</el-descriptions-item>
          <el-descriptions-item label="购买产品">{{
            billInfo.product_name
          }}</el-descriptions-item>
          <el-descriptions-item label="申请金额">{{
            billInfo.apply_repayment_principal
          }}</el-descriptions-item>
          <el-descriptions-item label="申请时间">{{
            billInfo.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="放款金额">
            {{ billInfo.reality_repayment_principal }}
          </el-descriptions-item>
          <el-descriptions-item label="放款时间">{{
            billInfo.create_time
          }}</el-descriptions-item>
          <el-descriptions-item :label="billInfo.stage_type != 3? '分期类型' : '账单类型'">
            {{ stageTypeList[billInfo.stage_type] }}
            <template v-if="billInfo.stage_type == 1">
              {{ billInfo.total_stage_count }}期</template
            >
            <template v-if="billInfo.stage_type == 2">
              {{ billInfo.x_payment_stage_count }}+{{
                billInfo.y_payment_stage_count
              }}期</template
            >
            <template v-if="billInfo.stage_type == 3">
              快捷支付
              </template
            >
          </el-descriptions-item>
          <el-descriptions-item label="机构推荐码">{{
            billInfo.recommend_code
          }}</el-descriptions-item>
          <el-descriptions-item label="备注">
            <span v-if="billInfo.remark">{{ billInfo.remark }}</span>
            <span v-else>暂无备注</span>
            <!-- <div class="remark-btn"><el-button style="margin:10px" type="primary" size="small" @click="editRemark">修改备注</el-button></div> -->
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </div>
    <div class="deitem" v-if="activeName == '费用信息'">
      <el-button
        type="primary"
        @click="getApplyBillDelay"
        style="margin: 10px 0px"
        >申请延期</el-button
      >
        <el-date-picker v-if="changeStatus" type="date" placeholder="更改还款日期" @input="changeTime" value-format="dd"
        style="margin: 10px 10px;"></el-date-picker>
      <base-table
        :columns="costInfo"
        :data="billStageList"
        :pagination="searchForm"
        @getData="initData"
        :loading="loading"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          slot="payment_status"
          label="还款状态"
          align="center"
          :formatter="paymentStatusFormatter"
        ></el-table-column>
        <el-table-column slot="is_delay_stage" label="是否延期" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.is_delay_stage == 0"> 否 </template>
            <template v-if="scope.row.is_delay_stage == 1">是</template>
          </template>
        </el-table-column>
        <el-table-column
          slot="overdue_day_count"
          label="逾期天数"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.overdue_day_count > 0"
              >{{ scope.row.overdue_day_count }}天</template
            >
            <!-- <template v-else>未逾期</template> -->
          </template>
        </el-table-column>
        <el-table-column
          slot="combine_stage_type"
          label="组合分期类型"
          align="center"
          :formatter="combineStageTypeFormatter"
        ></el-table-column>
        <el-table-column
          slot="options"
          label="操作"
          align="center"
          fixed="right"
          >
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="toRelief(scope.row)"
              type="text"
              size="small">
              <span v-if="scope.row.overdue_day_count > 0 && isCanRelief">减免还款</span>
            </el-button>
          </template>
        </el-table-column>
      </base-table>
    </div>
    <div class="deitem" v-if="activeName == '催收信息'">
      <div class="search" style="margin-bottom:10px">
        <el-form
          ref="searchRef"
          :model="UrgeForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="催收时间：">
                <el-date-picker
                  style="width: 100%"
                  v-model="Urge_date"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="setDateRange"
                  @change="onUrgeChange"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="催收类型：">
                <el-select
                  style="width: 100%"
                  v-model="UrgeForm.urgeType"
                  placeholder="请选择"
                  @change="searchUrge"
                >
                  <el-option
                    v-for="item in urgeTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <div style="margin-top: 8px">
                <el-checkbox v-model="checkedUrge" @change="onCheckedUrge"
                  >只查看当前账单</el-checkbox
                >
              </div>
            </el-col>
            <div style="text-align: right">
                <el-button size="small" @click="previewLetter">查看律师函</el-button>
                <el-button size="small" @click="searchUrge">搜索</el-button>
                <el-button
                  type="primary"
                  size="small"
                  @click="addUrge"
                  v-if="managerType != 0 || getAuth('addUrgeOrder')"
                  >添加</el-button
                >
              </div>
          </el-row>
        </el-form>
      </div>
      <base-table
        :columns="urgeColumns"
        :data="UrgeData"
        :pagination="UrgeForm"
        :total="Urge_total"
        @getData="getUrge"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          slot="urge_mode"
          label="催收模式"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.urge_mode == 1">电话</span>
            <span v-if="scope.row.urge_mode == 2">微信</span>
            <span v-if="scope.row.urge_mode == 3">其他</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="urge_type"
          label="催收类型"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.urge_type == 0">系统短信</span>
            <span v-if="scope.row.urge_type == 1">人工</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="sms_send_status"
          label="短信状态"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.sms_send_status == 1">成功</span>
            <span v-if="scope.row.sms_send_status == 2">失败</span>
          </template>
        </el-table-column>
      </base-table>
    </div>
    <refund ref="refund" />
    <refund-detail ref="RefundDetail" />
    <approve-modal ref="approveModal" @approveConfim="getApproveConfim" />
    <remark ref="remark" />
    <apply-bill-delay ref="applyBillDelay" />
    <addUrgeOrder ref="addUrgeOrder" @ok="searchUrge" />

    <div
      v-loading.fullscreen.lock="fullscreenLoading"
      element-loading-text="正在审核中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
  </div>
</template>

<script>
import columns from "./columns";
import {
  infoBill,
  cancelRefund,
  billApprove,
  billStageOverdueUrgeRecordList,
  billDateChange,
  getBillDateState,
  reliefStage
} from "@/api/bill.js";
import refund from "./components/refund.vue";
import approveModal from "./components/approve-modal.vue";
import RefundDetail from "./components/refund-detail.vue";
import Remark from "./components/remark.vue";
import ApplyBillDelay from "./components/apply-Bill-Delay.vue";
import addUrgeOrder from "./components/add-urge-order.vue";

import baseTable from "../../components/base-table/base-table.vue";
import { getAuth } from "@/utils/index.js";

export default {
  components: {
    refund,
    approveModal,
    RefundDetail,
    Remark,
    ApplyBillDelay,
    baseTable,
    addUrgeOrder,
  },
  name: "detail",
  data() {
    return {
      isAuthor: false,
      checkedUrge: true,
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },  
      Urge_date: [],
      Urge_total: 0,
      UrgeForm: {
        billNo: "",
        urgeType: -1,
        startDate: "",
        endDate: "",
        page: 1,
        per_page: 10,
      },
      UrgeData: [],
      urgeTypeList: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "系统",
        },
        {
          value: 1,
          label: "人工",
        },
      ],
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },

      showpop: true,
      ...columns,
      activeName: "账单信息",
      loading: false,
      searchForm: {},
      billStageList: [],
      bill_id: "",
      bill_no: "",
      finishStatus: {
        0: "wait",
        1: "success",
        2: "error",
      },
      billInfo: {},
      approveList: {},
      genderList: {
        0: "保密",
        1: "男",
        2: "女",
      },
      educationList: {
        0: "无",
        1: "小学",
        2: "初中",
        3: "高中",
        4: "中专",
        5: "大专",
        6: "本科",
        7: "硕士",
        8: "博士",
        9: "博士后",
      },
      stageTypeList: {
        0: "全款",
        1: "等额还款",
        2: "组合还款",
      },
      cardTypeList: {
        0: "借记卡",
        1: "信用卡",
      },
      ucStatusList: {
        0: "解绑",
        1: "使用中",
      },
      fullscreenLoading: false,
      changeStatus: false,
      isApproveAuth: false,
      isRefundAuth: false,
      isCanRelief: false
    };
  },

  mounted() {
    // this.bill_id = this.$route.query.bill_id;
    if (localStorage.getItem("auth")) {
      const auth = JSON.parse(localStorage.getItem("auth"))
      auth.map(item => {
        if (item.auth_key == 'reliefStage') {
          this.isCanRelief = true
        }
      })
    }
    if (localStorage.getItem("managerType") != 0) {
      this.isCanRelief = true
    }
    this.bill_no = this.$route.query.bill_no;
    this.initData();
  },
  methods: {
    changeTime(val) {
      this.changeBillState(val)
    },
    async changeBillState(val) {
      const param = {
        billId: this.bill_id,
        date: val
      };
      const { code, data } = await billDateChange(param);
      if (code == 200) {
        // 成功
        console.log(data)
        this.getBillList()
      } else if (code == 20002) {
        // 谈吐司
      }
    },
    toRelief(row) {
      // todo 申请延期
      // reliefStage
      this.$confirm(`是否对第${row.current_stage}进行减免？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
          const parmes = {
            billStageID: row.id,
          };
          const {code} = await reliefStage(parmes)
          if(code == 200){
            this.initData()
            this.$message({
              type: "success",
              message: "减免成功!",
            });
          }
        })
        .catch(() => {});
    },
    async initData() {
      let that = this
      if (localStorage.getItem('managerType') == 1 || localStorage.getItem('managerType') == 2) {
        that.isApproveAuth = true;
        that.isRefundAuth = true;
      } else {
        JSON.parse(localStorage.getItem('auth')).map(item => {
        if (item.auth_title == '账单审核') {
          that.isApproveAuth = true;
        }
        if (item.auth_title == '账单退费') {
          that.isRefundAuth = true;
        }
      })
      }
      this.getBillList()
      this.getChangeStatus()
    },
    async getBillList() {
      const param = {
        bill_no: this.bill_no,
      };
      const { code, data } = await infoBill(param);
      if (code == 200) {
        this.bill_id = data.billInfo.bill_id;
        this.billInfo = data.billInfo;
        var i = 0
        this.billStageList = data.billStageList.map((item) => {
          if (item.early_repayment_fees != '0.00') {
            item.current_stage = '部分还款'
          } else {
            item.current_stage = ++i
          }
          return item;
        });
        this.approveList = data.approveList;
        this.billRefundList = data.billRefundList;
        // this.UrgeForm.billNo = data.billInfo.bill_no;
        if(this.managerType != 0 || this.getAuth('urgeOrderList')){
          await this.getUrge();
        }
      }
    },
    async getChangeStatus() {
      const param = {
        billNo: this.bill_no,
      }
      const {code, data} = await getBillDateState(param)
      if (code == 200) {
        this.changeStatus = data.change_status
      }
    },
    addUrge() {
      this.$refs.addUrgeOrder.isShow(this.billInfo.bill_no);
    },
    onCheckedUrge(value) {
      if (value) {
        this.UrgeForm.billNo = this.billInfo.bill_no;
      } else {
        this.UrgeForm.billNo = "";
      }
      this.searchUrge();
    },
    async getUrge() {
      this.UrgeForm.userId = this.billInfo.user_id;
      if(this.checkedUrge){
        this.UrgeForm.billNo = this.billInfo.bill_no;
      }
      const { code, data } = await billStageOverdueUrgeRecordList(
        this.UrgeForm
      );
      if (code == 200) {
        this.UrgeData = data.data;
        this.Urge_total = data.total;
      }
    },
    searchUrge() {
      this.UrgeForm.page = 1;
      this.UrgeForm.per_page = 10;
      this.getUrge(this.billInfo.user_id);
    },
    onUrgeChange(val) {
      if (val) {
        this.UrgeForm.startDate = val[0];
        this.UrgeForm.endDate = val[1];
      } else {
        this.UrgeForm.startDate = "";
        this.UrgeForm.endDate = "";
      }
      this.searchUrge();
    },
    getApplyBillDelay() {
      this.$refs.applyBillDelay.isShow(this.billInfo.bill_no);
    },
    showDatePop() {

    },
    editRemark() {
      this.$refs.remark.isShow(this.billInfo.bill_no, this.billInfo.remark);
    },
    onApproveData(item) {
      this.$refs.approveModal.isShow("审核资料", item, this.bill_id);
    },
    onApproveInstitution(item) {
      this.$refs.approveModal.isShow("机构审核", item, this.bill_id);
    },
    async getApproveConfim(params) {
      this.fullscreenLoading = true;
      const { code } = await billApprove(params);
      if (code == 200) {
        this.fullscreenLoading = false;
        this.$refs.approveModal.close();
        this.initData();
        this.$message.success("操作成功");
      } else {
        this.fullscreenLoading = false;
      }
    },
    onRefund() {
      this.$refs.refund.isShow(
        this.billInfo.bill_id,
        this.billInfo.refund_rate
      );
    },
    getCheckRedund() {
      this.$refs.RefundDetail.isShow(this.billRefundList);
    },
    onCancelRefund() {
      let that = this;
      this.$confirm("是否取消退款？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const params = {
            billId: this.billInfo.bill_id,
          };
          const { code } = await cancelRefund(params);
          if (code == 200) {
            that.$message({
              type: "success",
              message: "操作成功!",
            });
            that.initData();
          }
        })
        .catch(() => {});
    },
    postpone(row) {
      console.log(row);
    },
    handleClick(tab) {
      this.activeName = tab.name;
    },
    paymentStatusFormatter(row) {
      switch (row.payment_status) {
        case 0:
          return "未支付";
        case 1:
          return "部分还款";
        case 2:
          return "已还款";
        default:
          return "其他";
      }
    },
    isDelayStageFormatter(row) {
      switch (row.is_delay_stage) {
        case 0:
          return "否";
        case 1:
          return "是";
      }
    },
    isOverdueFormatter(row) {
      switch (row.is_overdue) {
        case 0:
          return "否";
        case 1:
          return "是";
        default:
          return "其他";
      }
    },
    combineStageTypeFormatter(row) {
      switch (row.combine_stage_type) {
        case 0:
          return "无";
        case 1:
          return "X期";
        case 2:
          return "Y期";
        default:
          return "其他";
      }
    },
    previewLetter() {
      const routeData = this.$router.resolve({
        path: "/order/lawyer",
        query: {
          billID: this.bill_id,
        },
      });
      window.open(routeData.href, "_blank");
    }
  },
};
</script>

<style lang="scss" scoped>
.ordetail {
  .opdetcru {
    margin-bottom: 20px;
  }
  .tabbarsa {
    margin-bottom: 16px;
  }
  .basic-info {
    .user-info {
      display: flex;
      align-items: center;
      .head-pic {
        font-size: 30px;
        margin-right: 10px;
      }
      .head-bd {
        align-items: center;
        .name {
          font-size: 16px;
        }
      }
    }
    .tip-info {
      display: flex;
      margin-left: 20px;
    }
  }
  .deitem {
    .cardInfo {
      margin-top: 20px;
      .remark-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
    .progress {
      display: flex;
      .prosteps {
        width: 50%;
        min-width: 500px;
        margin-top: 20px;
        margin-right: 20px;
      }
      .refundInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .refundTitle {
          margin: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .already-redund {
          .titlt {
            display: flex;
            align-items: center;
            .checkRedund {
              margin: 10px;
              padding: 5px 10px;
              cursor: pointer;
              background: #169bd5;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-tabs__content {
  overflow: visible;
  border: 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0;
}
::v-deep .el-tabs__item {
  color: #515a6e;
  background-color: #f8f8f9;
  margin-right: 5px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: 1px solid #e4e7ed;
  border-radius: 5px 5px 0px 0px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-color: #57a3f3;
  border-bottom-color: #fff;
}
::v-deep .el-tabs__item.is-active {
  color: #57a3f3;
  background-color: #fff;
  border-color: #57a3f3;
}
</style>
<style>
.labelc {
  width: 25% !important;
  text-align: center !important;
  color: #495060 !important;
  font-weight: 700 !important;
  background-color: #f8f8f9 !important;
}
.connamc {
  width: 25% !important;
}
</style>