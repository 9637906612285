<template>
  <div>
    <el-dialog
      :title="title"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small" label-width="110px" ref="ruleForm" :rules="rules">
        <el-form-item label="账单编号：">
          <span>
            {{ form.billSign }}
          </span>
        </el-form-item>
        <el-form-item label="催收跟进人：" prop="urgeUsername">
          <el-input
            v-model="form.urgeUsername"
            placeholder="请输入催收跟进人"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="催收模式：" prop="urgeMode">
          <el-radio-group v-model="form.urgeMode">
            <el-radio :label="1">电话</el-radio>
            <el-radio :label="2">微信</el-radio>
            <el-radio :label="3">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="用户手机号：">
          <el-input
            v-model="form.userMobile"
            placeholder="请输入用户手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="催收内容：" prop="urgeContent">
          <el-input
            type="textarea"
            placeholder="请输入催收内容"
            v-model="form.urgeContent"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('ruleForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addBillStageOverdueUrgeRecord } from "@/api/bill.js";
export default {
  name: "addUrgeOrder",

  data() {
    return {
      title: "添加催收记录",
      dialogFormVisible: false,
      form: {
        billSign: "",
        urgeUsername: "",
        userMobile: "",
        urgeMode:1,
        urgeContent: "",
      },
      rules: {
        urgeUsername: [
          { required: true, message: "请输入催收跟进人", trigger: "blur" },
        ],
        urgeMode: [
          { required: true, message: "请选择催收模式", trigger: "change" },
        ],
        urgeContent: [
          { required: true, message: "请输入催收内容", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {},

  methods: {
    async isShow(bill_no) {
      this.dialogFormVisible = true;
      this.form.billSign = bill_no
    },

    confirm(formName) {
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          const {code} = await addBillStageOverdueUrgeRecord(this.form)
          if(code == 200){
            this.$message.success("添加成功");
            this.$emit('ok')
            this.close()
          } 
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs['ruleForm'].resetFields();
      this.form = { 
        billSign: "",
        urgeUsername: "",
        userMobile: "",
        urgeMode:1,
        urgeContent: "",
      }
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>