<template>
  <div>
    <el-dialog
      :title="title"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small" label-width="120px">
        <el-form-item label="退款手续费：" prop="skipRefund">
          <el-radio-group
            v-model="form.skipRefund"
            @change="onSkipRefundChange"
          >
            <el-radio :label="0">收取手续费</el-radio>
            <el-radio :label="1">无手续费</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="手续费金额："
          prop="refundPrice"
          v-if="form.skipRefund == 0"
        >
          <el-input
            v-model="form.refundPrice"
            placeholder="退费续费金额"
            type="number"
            style="width: 200px"
            @change="onPriceChange"
            @input="onPriceChange"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="form.skipRefund == 0">
          <div style="color: #b5b5b5"
            >商家可得{{ 100 - Number(refund_rate) }}%，共计{{ business_refund_money }}元</div>
          <div style="color: #b5b5b5"
            >平台可得{{ refund_rate }}%，共计{{ platform_refund_money }}元</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { applyRefund } from "@/api/bill.js";
export default {
  name: "refund",

  data() {
    return {
      title: "退费申请",
      dialogFormVisible: false,
      form: {
        skipRefund: 0,
        refundPrice: 0,
      },
      refund_rate: 0,
      business_refund_money: 0,
      platform_refund_money: 0,
    };
  },

  mounted() {},

  methods: {
    async isShow(bill_id, refund_rate) {
      this.dialogFormVisible = true;
      this.form.billId = bill_id;
      this.refund_rate = refund_rate;
    },
    onPriceChange(val) {
      this.platform_refund_money = Math.round(Number(val) * Number(this.refund_rate / 100) * 100) / 100;
      this.business_refund_money = Math.round((Number(val) - this.platform_refund_money) * 100) / 100;
    },
    onSkipRefundChange(val) {
      if (val == 1) {
        this.form.refundPrice = 0;
        this.business_refund_money = 0
        this.platform_refund_money = 0
      }
    },
    async confirm() {
      if (this.form.skipRefund == 1) {
        this.form.refundPrice = "";
      } else {
        this.form.refundPrice = Number(this.form.refundPrice) || 0;
        if (this.form.refundPrice <= 0) {
          this.$message({
            message: "请输入正确金额",
            type: "warning",
          });
          return;
        }
      }

      const { code } = await applyRefund(this.form);
      if (code == 200) {
        if (this.form.skipRefund == 1) {
          this.$message.success("已退费");
        } else {
          this.$message.info("已发起退款，请用户支付手续费");
        }
        this.close();
        this.$parent.initData();
      }
    },
    close() {
      this.dialogFormVisible = false;
      this.business_refund_money = 0
      this.platform_refund_money = 0
      this.form = {
        skipRefund: 0,
        refundPrice: 0,
      };
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>