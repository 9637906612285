<template>
  <div class="refundDetail">
    <el-dialog
      title="退费详情"
      width="600px"
      :close-on-click-modal="true"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <div class="log-table">
        <el-table
          :data="billRefundList"
          header-cell-class-name="table_header"
          row-key="id"
          border
          max-height="250"
        >
          <el-table-column prop="trade_amount" label="交易金额" align="center" />
          <el-table-column prop="refund_platform_income" label="平台收入" align="center" />
          <el-table-column prop="refund_business_income" label="商家收入" align="center" />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "refundDetail",

  data() {
    return {
      dialogFormVisible: false,
      billRefundList: [],
    };
  },

  mounted() {},

  methods: {
    async isShow(billRefundList) {
      this.dialogFormVisible = true;
      this.billRefundList = billRefundList;
    },

    close() {
      this.dialogFormVisible = false;
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.refundDetail {
  /deep/ .customW {
    border-radius: 10px !important;
    .el-dialog__header {
      border-bottom: 1px solid #e8eaec;
      .el-dialog__title {
        font-size: 14px;
        color: #17233d;
        font-weight: 700;
      }
    }
    .el-dialog__footer {
      border-top: 1px solid #e8eaec;
    }
  }
  .log-table {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>