export const allOrder = [
  {
    label: '订单编号',
    prop: 'bill_no',
    align: 'center',
    width: 160,
  }, 
  {
    label: '姓名',
    prop: 'real_name',
    align: 'center',
    width: 80,
  },
  {
    label: '手机号',
    prop: 'mobile',
    align: 'center',
    width: 110,
  },
  {
    label: '年龄',
    prop: 'age',
    align: 'center',
    width: 110,
  },
  {
    label: '邮箱',
    prop: 'email',
    align: 'center',
    width: 110,
  },
  {
    label: '商家名称',
    prop: 'full_name',
    align: 'center',
    width: 110,
  },
  {
    label: '产品类型',
    slot: 'stage_type',
  },
  {
    label: '申请日期',
    prop: 'create_time',
    align: 'center',
    width: 150,
  },
  {
    label: '申请金额',
    prop: 'apply_repayment_principal',
    align: 'center',
    width: 80,
  },
  {
    label: '授信金额',
    // prop: 'reality_repayment_principal',
    slot: 'reality_repay',
    align: 'center',
    width: 80,
  },
  {
    label: '申请期限',
    prop: 'total_stage_count',
    align: 'center',
    width: 80,
  },
  {
    label: '审核状态',
    slot: 'approve_status',
  },
  {
    label: '订单状态',
    slot: 'bill_status',
  },
  {
    label: '有身份证照片',
    slot: 'is_id_card_img',
  },
  {
    label: '绑定银行卡',
    slot: 'is_bind_card',
  },
  {
    label: '放款日期',
    prop: 'approve_time',
    align: 'center',
    width: 150,
  },
  {
    label: '结清日期',
    prop: 'settle_time',
    align: 'center',
    width: 150,
  },
  {
    label: '退费时间',
    prop: 'refund_time',
    align: 'center',
    width: 150,
  },
  {
    label: '备注',
    slot: 'remark',
  },
  {
    label: '操作',
    slot: 'operation',
  },
]

export const ledgerOrder = [
  {
    label: '账单编号',
    slot: 'bill_no',
  },
  {
    label: '学员',
    prop: 'real_name',
    align: 'center',
  },
  {
    label: '主体',
    prop: 'entity_name',
    align: 'center',
  },
  {
    label: '一级商家',
    prop: 'headquarters_name',
    align: 'center',
  },
  {
    label: '二级商家',
    prop: 'full_name',
    align: 'center',
  },
  {
    label: '产品名称',
    prop: 'product_name',
    align: 'center',
  },
  {
    label: '类型',
    slot: 'ledger_channel',
  },
  {
    label: '分账金额',
    prop: 'ledger_amount',
    align: 'center',
    width: 90,
  },
  {
    label: '实际本金',
    prop: 'reality_repayment_principal',
    align: 'center',
    width: 90,
  },
  {
    label: '期数',
    slot: 'ledger_count',
  },
  {
    label: '申请时间',
    prop: 'approve_time',
    align: 'center',
  },
  {
    label: '还款时间',
    prop: 'create_time',
    align: 'center',
  },
  {
    label: '分账时间',
    prop: 'divide_complete_time',
    align: 'center',
  },
  {
    label: '分账状态',
    slot: 'divide_status',
  },
  {
    label: '到账状态',
    slot: 'receipt_status',
  },
  {
    label: '分账失败原因',
    prop: 'fail_reason',
    align: 'center',
  },
  {
    label: '还款类型',
    slot: 'refund_type',
  },
  {
    label: '操作',
    slot: 'operation',
  },
]

export const overdueOrder = [
  {
    label: '订单编号',
    prop: 'order_num',
    align: 'center',
    width: '230',
  },
  {
    label: '姓名',
    prop: 'name',
    align: 'center',
    width: '130',
  },
  {
    label: '手机号',
    prop: 'phone',
    align: 'center',
    width: '150',
  },
  {
    label: '产品类型',
    prop: 'product_type',
    align: 'center',
    width: '150',
  },
  {
    label: '学历',
    prop: 'education_background',
    align: 'center',
    width: '120',
  },
  {
    label: '放款日期',
    prop: 'loan_date',
    align: 'center',
    width: '150',
  },
  {
    label: '放款金额(元)',
    prop: 'loan_money',
    align: 'center',
    width: '150',
  },
  {
    label: '期限(月)',
    prop: 'time_limit',
    align: 'center',
    width: '110',
  },
  {
    label: '逾期天数',
    prop: 'overdue_day_num',
    align: 'center',
    width: '110',
  },
  {
    label: '订单状态',
    prop: 'application_state',
    align: 'center',
  },
  {
    label: '二级机构',
    prop: 'second_organization',
    align: 'center',
  },
  {
    label: '三级机构',
    prop: 'thirdly_organization',
    align: 'center',
  },
  {
    label: '操作',
    slot: 'operation',
  },
]

export const refundOrder = [
  {
    label: '订单编号',
    prop: 'order_num',
    align: 'center',
    width: '230',
  },
  {
    label: '姓名',
    prop: 'name',
    align: 'center',
    width: '100',
  },
  {
    label: '手机号',
    prop: 'phone',
    align: 'center',
    width: '120',
  },
  {
    label: '产品类型',
    prop: 'product_type',
    align: 'center',
    width: '120',
  },
  {
    label: '机构实际应退金额',
    prop: 'refund_amount',
    align: 'center',
    width: '150',
  },
  {
    label: '退费状态',
    prop: 'refund_status',
    align: 'center',
    width: '100',
  },
  {
    label: '申请退费日期',
    prop: 'application_refund_date',
    align: 'center',
    width: '120',
  },
  {
    label: '结清日期',
    prop: 'clear_date',
    align: 'center',
    width: '120',
  },
  {
    label: '放款金额(元)',
    prop: 'loan_money',
    align: 'center',
    width: '120',
  },
  {
    label: '期限(月)',
    prop: 'time_limit',
    align: 'center',
    width: '120',
  },
  {
    label: '订单状态',
    prop: 'application_state',
    align: 'center',
  },
  {
    label: '二级机构',
    prop: 'second_organization',
    align: 'center',
  },
  {
    label: '三级机构',
    prop: 'thirdly_organization',
    align: 'center',
  },
  {
    label: '操作',
    slot: 'operation',
  },
]

export const costInfo = [
  {
    label: '当前分期',
    prop: 'current_stage',
    width: '80',
    align: 'center',
  },
  {
    label: '到期日',
    prop: 'repayment_date',
    align: 'center',
  },
  {
    label: '总额',
    prop: 'total_stage_repayment',
    align: 'center',
  },
  {
    label: '本金',
    prop: 'repayment_principal',
    align: 'center',
  },
  {
    label: '剩余本金',
    prop: 'remain_repayment_principal',
    align: 'center',
  },
  {
    label: '还款状态',
    slot: 'payment_status',
  },
  // {
  //   label: '是否结清',
  //   slot: 'is_settle',
  // },
  {
    label: '平台管理费(用户承担)',
    prop: 'platform_interest_user_payer',
    align: 'center',
  },
  {
    label: '平台管理费(商家贴息)',
    prop: 'platform_interest_business_payer',
    align: 'center',
  },
  {
    label: '商家管理费',
    prop: 'business_interest',
    align: 'center',
  },
  {
    label: '组合分期类型',
    slot: 'combine_stage_type',
  },
  {
    label: '是否延期',
    slot: 'is_delay_stage',
  },
  // {
  //   label: '是否逾期',
  //   slot: 'is_overdue',
  // },
  {
    label: '逾期天数',
    slot: 'overdue_day_count',
    align: 'center',
  },
  // {
  //   label: '逾期金额',
  //   prop: 'overdue_amount',
  //   align: 'center',
  // },
  {
    label: '滞纳金',
    prop: 'overdue_late_fee',
    align: 'center',
  },
  {
    label: '逾期罚金',
    prop: 'overdue_fine',
    align: 'center',
  },
  {
    label: '提前还款手续费',
    prop: 'early_repayment_fees',
    align: 'center'
  },
  {
    label: '操作',
    slot: 'options'
  }
]

export const stageCol = [
  {
    label: '账单编号',
    slot: 'bill_no',
  }, 
  {
    label: '商家',
    prop: 'business_full_name',
    align: 'center',
    width: 150,
  },
  {
    label: '姓名',
    prop: 'real_name',
    align: 'center',
    width: 80,

  },
  {
    label: '手机号',
    prop: 'mobile',
    align: 'center',
    width: 110,
  },
  {
    label: '期数',
    slot: 'current_stage',
  },
  {
    label: '应还款日期',
    prop: 'repayment_date',
    align: 'center',
    width: 150,

  },
  {
    label: '应还总额',
    prop: 'should_repayment_amount',
    align: 'center',
  },
  {
    label: '应还本金',
    prop: 'should_repayment_principal',
    align: 'center',
  },
  {
    label: 'x期贴息金额',
    slot: 'should_platform_interest_business_payer',
  },
  {
    label: '逾期费',
    prop: 'should_repayment_overdue',
    align: 'center',
  },
  {
    label: '状态',
    slot: 'bill_stage_status',
  },
  {
    label: '逾期天数',
    prop: 'overdue_day_count',
    align: 'center',
  },
  {
    label: '还款日期',
    prop: 'payment_date',
    align: 'center',
    width: 150,

  },
  {
    label: '实还总额',
    prop: 'repayment_amount',
    align: 'center',
  },
  {
    label: '实还本金',
    prop: 'repayment_principal',
    align: 'center',
  },
  {
    label: '商家管理费',
    prop: 'repayment_business_interest',
    align: 'center',
  },
  {
    label: '商家可得',
    prop: 'repayment_business_amount',
    align: 'center',
  },
  {
    label: '平台可得',
    prop: 'repayment_platform_amount',
    align: 'center',
  },
  {
    label: '还款模式',
    slot: 'payment_mode',
  },

]

export const urgeColumns = [
  {
    label: '账单编号',
    prop: 'bill_no',
    align: 'center',
    width:180
  },
  {
    label: '用户手机号',
    prop: 'user_mobile',
    align: 'center',
    width:120
  },
  {
    label: '催收模式',
    slot: 'urge_mode',
  },
  {
    label: '催收类型',
    slot: 'urge_type',
  },
  {
    label: '系统短信发送状态',
    slot: 'sms_send_status',
  },
  {
    label: '催收内容',
    prop: 'urge_content',
    align: 'center',
  },
  {
    label: '催收时间',
    prop: 'create_time',
    align: 'center',
    width:150
  },
  {
    label: '催收跟进人',
    prop: 'urge_username',
    align: 'center',
    width:100
  },
  
 
]

export const orderList = [
  {
    label: '期数',
    prop: 'current_stage',
    align: 'center',
  }, 
  {
    label: '应付服务费',
    prop: 'total_stage_repayment',
    align: 'center',
  }, 
  {
    label: '付款日期',
    prop: 'repayment_date',
    align: 'center',
  },
]
export default {
  allOrder,
  ledgerOrder,
  overdueOrder,
  refundOrder,
  costInfo,
  stageCol,
  urgeColumns,
  orderList
}
